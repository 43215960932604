'use strict';
import { app } from './firebase';
const API_VERSION = 'v1';
const AUTH_TOKEN = 'Q$WazpNmb#Y&MhDOJ@9&h4AX^Y&GK&tJ841^Ej5r#@SVmZ5J@g';

export const getBaseUrl = (local: boolean) => {
  if (local) return 'http://localhost:3001';
  const { projectId } = app.options;
  let baseUrl = '';
  switch (projectId) {
    case 'condomi-prod':
      baseUrl = `https://api-gw-b21lg2hn.uc.gateway.dev/${API_VERSION}`;
      break;
    case 'condomi-stg':
      baseUrl = `https://api-gw-stg-d9660lmp.uc.gateway.dev/${API_VERSION}`;
      break;
    case 'condomi-dev':
      baseUrl = `https://api-gw-dev-9zehbpvg.uc.gateway.dev/${API_VERSION}`;
      break;
    case 'condomi-dev2':
      baseUrl = `https://api-gw-dev2-cgu312f3.uc.gateway.dev/${API_VERSION}`;
      break;
  }
  console.log(baseUrl);
  return baseUrl;
};

export const getApi = async ({
  path,
  local = false,
}: {
  path: string;
  local: boolean;
}) => {
  let headers = {
    'X-Zeta-Token': AUTH_TOKEN,
  };
  let baseUrl = getBaseUrl(local);
  const url = `${baseUrl}${path}`;
  return { url, headers, timeout: 50000 };
};
