import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { testCNPJ } from './validations';
dayjs.locale('pt-br');
dayjs.extend(localizedFormat);

export type FieldType =
  | 'bigint'
  | 'boolean'
  | 'number'
  | 'dateTime'
  | 'futureDate'
  | 'date'
  | 'money'
  | 'percentage'
  | 'input'
  | 'docNumber'
  | 'phone'
  | 'check'
  | 'select'
  | 'ispb'
  | 'dayOfWeek';

export type FieldFormatOpts = {
  decimals?: number;
  moneySymbol?: string;
};

export const format = (
  v: unknown,
  type?: FieldType,
  opts?: FieldFormatOpts
): string => {
  const { decimals, moneySymbol } = opts ?? {};
  const resolvedType = type ?? typeof v;
  const resolvedMoneySymbol = moneySymbol ?? 'R$';
  const resolvedDecimals = type === 'money' ? decimals ?? 2 : decimals;

  const numberFormatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: resolvedDecimals,
    maximumFractionDigits: resolvedDecimals,
  });

  switch (resolvedType) {
    case 'bigint':
      return (v as bigint).toString();
    case 'check':
    case 'boolean':
      return v ? 'sim' : 'não';
    case 'function':
      return '-';
    case 'money':
      return `${resolvedMoneySymbol} ${numberFormatter.format(v as number)}`;
    case 'number':
      return numberFormatter.format(v as number);
    case 'percentage':
      return `${numberFormatter.format((v as number) * 100)} %`;
    case 'dateTime':
      return dayjs(v as string).format('L LT');
    case 'futureDate':
    case 'date':
      return dayjs(v as string).format('DD/MMM/YYYY');
    case 'dayOfWeek':
      return dayjs(v as string).format('ddd');
    case 'object':
      if (v instanceof Date) return dayjs(v).format('L LT');
      else return '-';
    case 'string':
      return v as string;
    case 'symbol':
      return '-';
    case 'docNumber': {
      if (!v) return '-';
      const value = v as string;
      if (testCNPJ(value))
        return `${value.substring(0, 2)}.${value.substring(
          2,
          5
        )}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(
          12
        )}`;
      else
        return `${value.substring(0, 3)}.${value.substring(
          3,
          6
        )}.${value.substring(6, 9)}-${value.substring(9)}`;
    }
    case 'phone': {
      if (!v) return '-';
      const value = v as string;
      if (value[0] === '+') return value;
      let nationalPhone = value;
      //if (value.slice(0, 2) === '55') nationalPhone = value.slice(2);
      //else nationalPhone = value;
      const ix = nationalPhone.length - 4;
      return `(${nationalPhone.substring(0, 2)}) ${nationalPhone.substring(
        2,
        ix
      )}-${nationalPhone.substring(ix)}`;
    }
    case 'ispb':
      return (v as string).padStart(8, '0');
    case 'undefined':
    case 'select':
    default:
      return '-';
  }
};
